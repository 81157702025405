<template>
  <div>
    <div class="page">
      <div class="formcontainer">
        <div class="formbox">
          <h2>Hizmet Sağlayıcılar için Başvuru Formu</h2>
          <!-- <tabs-basvuru current="1" hide="6"></tabs-basvuru>
          <tabs-basvuru-sub current="2" hide="6"></tabs-basvuru-sub>-->

          <tabs-new-basvuru current="2" hide="6"></tabs-new-basvuru>
          <tabs-edevlet-basvuru-sub v-if="loggedIn" current="1" hide="6"></tabs-edevlet-basvuru-sub>
          <tabs-eimza-basvuru-sub v-else current="1" hide="6"></tabs-eimza-basvuru-sub>

          <div class="formhead">
            <p>TC Kimlik numaranız, vermiş olduğunuz MERSİS numarasına ait kayıtlarda aktif durumdaki imza yetkilileri arasında yer almalıdır.</p>
            <!-- <p>MERSİS numaranız bulunmuyorsa işlemlere devam edilemediğinden lütfen bizimle <router-link to="/iletisim" class="blue ml"> iletişim</router-link>e geçiniz.</p> -->
          </div>

          <transition name="fade">
            <div class="formpart mt">
              <div class="formleft">
                <h3>Ticari Bilgiler</h3>

                <form action="#" onsubmit="return false;">
                  <iys-input
                    :focused="true"
                    label="MERSİS Numarası"
                    v-model="mersisNo"
                    mask="9999999999999999"
                    :problem="problem.mersisNo"
                    @input="problem.mersisNo = ''"
                    @enter="NEXT"
                  />
                </form>
              </div>

              <div class="formright">
                <h3>Yetkili Kişi Bilgileri</h3>

                <form action="#" onsubmit="return false;">
                  <iys-input
                    ref="tckn"
                    label="TC Kimlik Numarası"
                    v-model="tckn"
                    mask="99999999999"
                    :problem="problem.tckn"
                    @input="
                      problem.tckn = '';
                      tckn.length == 11 ? CHECK_TCKN() : null;
                    "
                    @enter="NEXT"
                    @blur="CHECK_TCKN"
                    :fixed="loggedIn ? true : false"
                  />
                </form>
              </div>
            </div>
          </transition>

          <sonraki-bar
            tabindex="2"
            :dsbl="!next"
            @next="next ? NEXT() : null"
            :no-app-no="true"
            label="Sorgula"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CepTelDogrulama from "./genel/CepTelDogrulama";
import SonrakiBar from "./genel/SonrakiBar";
import isValidTCKN from "../../../misc/isValidTCKN";

export default {
  name: "Form",

  components: {
    CepTelDogrulama,
    SonrakiBar,
  },

  data() {
    return {
      cepTelDogrulaState: "",

      mersisNo: "",
      tckn: "",

      problem: {
        mersisNo: "",
        tckn: "",
      },

      enteredBefore: {
        tckn: false,
      },
    };
  },

  mounted() {
    this.tckn = this.tckn_keycloak;

    if (this.$env.MOCK) {
      this.MOCK();
    }
  },

  computed: {
    ...mapState("applicant", ["user", "application"]),

    next() {
      return (
        this.mersisNo.length === 16 &&
        this.tckn.length === 11 &&
        this.tckn_is_valid
      );
    },

    tckn_is_valid() {
      return isValidTCKN(this.tckn);
    },
  },

  methods: {
    ...mapActions("applicant", ["mersis_dogrula"]),
    ...mapMutations("applicant", ["MERGE_TO_APPLICATION"]),

    CHECK_TCKN() {
      !this.tckn_is_valid
        ? (this.problem.tckn = "Geçerli bir TC Kimlik Numarası giriniz.")
        : (this.problem.tckn = "");
    },

    NEXT() {
      if (!this.next) return;

      this.MERGE_TO_APPLICATION({
        mersisNo: this.mersisNo,
        contact: {
          tcKimlikNo: this.tckn,
        },
      });

      this.mersis_dogrula()
        .then((res) => {
          // _paq.push(["trackEvent", "Basvuru", "Mersis_Sorgula", "Success"]);
          this.MERGE_TO_APPLICATION(res.payload);
          this.$router.push("/hizmet-saglayici/basvuru/form");
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Basvuru", "Mersis_Sorgula", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    MOCK() {
      this.Toast(`Development mode: Filling form with mock data!!!`);

      this.mersisNo = "3233332160600013"; //Emre
      this.tckn = "32333321606";

      // this.mersisNo = "0622051788400014"; //Emre
      // this.tckn = "10523148004";

      // this.mersisNo = "0073001800000022"; //Arcelik
      // this.tckn = "12001049568";
    },
  },
};
</script>

<style scoped>
.input_fix_murat {
  pointer-events: none;
  background-color: #ebf6ff;
  border: #ffffff;
  font-weight: 600;
}

.pointer_murat {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.izinsection {
  display: flex;
  flex-direction: column;
  margin: 4vh auto;
  width: calc(100% - 4vh);
  border-radius: 0.5vw;
  padding: 1vw;
}

.izinsection label {
  color: #55565e;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}

.izintable .izinline .title {
  margin-right: 0;
  text-align: left;
}
.izintable .izinline .radio-group {
  justify-content: center;
}

.hizmet-sozlesmesi-all .hizmet-sozlesmesi {
  display: block;
  height: 20vh;
  background: #f9f9f9;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0.5vw;
  border: 0.5vw #f9f9f9 solid;
  border-radius: 0.5vw;
  margin-bottom: 1vw;
}
</style>
